<template>
  <div class="inhalt-overview" :class="$mq" id="inhalt">
    <div class="inhalt desktop-inhalt" :class="$mq" id="desktop-inhalt">
      <div class="inhalt__wrapper" :class="$mq">
        <div class="inhalt-left" :class="$mq">
          <h1
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 250,
              origin: 'bottom',
              distance: '40px',
            }"
          >
            Eine feinsinnige Komödie über<br />
            das Zuviel und das Zuwenig.
          </h1>
          <div class="text-inhalt" :class="$mq">
            <p
              v-scroll-reveal.reset="{
                duration: 1000,
                delay: 350,
                origin: 'bottom',
                distance: '40px',
              }"
            >
              Marlen ist früher durch die Welt gereist, draußen strahlt sie,
              aber ihre Wohnung hat sie mit Gegenständen vollgestellt. Sie ist
              eine leidenschaftliche Sammlerin. Alles ist zu wertvoll, zu
              bedeutend, um es wegzuschmeißen. Fynn dagegen will mit nur 100
              Dingen durch die Welt gehen.
            </p>
            <p
              class="middle"
              v-scroll-reveal.reset="{
                duration: 1000,
                delay: 450,
                origin: 'bottom',
                distance: '40px',
              }"
            >
              <span> »Ordnung ist das halbe Leben!«,</span> sagt er.
              <span>»Willkommen in der anderen Hälfte!«,</span>
              antwortet sie.
            </p>
            <p
              v-scroll-reveal.reset="{
                duration: 1000,
                delay: 550,
                origin: 'bottom',
                distance: '40px',
              }"
            >
              Das ist der Humor und der Kampfstil, den sie von jetzt an pflegen.
              Marlen und Fynn – stärker könnten die Gegensätze nicht sein. Doch
              Gegensätze ziehen sich ja bekanntlich an …
            </p>
          </div>
        </div>
        <div class="inhalt-right" :class="$mq">
          <div
            class="bild-1"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 250,
              origin: 'right',
              distance: '40px',
            }"
          >
            <img src="@/assets/images/bild_1.png" alt="" />
          </div>
          <div
            class="name-1"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 250,
              origin: 'right',
              distance: '40px',
            }"
          >
            <img src="@/assets/images/corinna.png" alt="" />
          </div>
          <div
            class="bild-2"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 650,
              origin: 'right',
              distance: '40px',
            }"
          >
            <img src="@/assets/images/bild_2.png" alt="" />
          </div>
          <div
            class="bild-3"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 1050,
              origin: 'right',
              distance: '40px',
            }"
          >
            <img src="@/assets/images/bild_3.png" alt="" />
          </div>
          <div
            class="name-2"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 1050,
              origin: 'right',
              distance: '40px',
            }"
          >
            <img src="@/assets/images/daniel.png" alt="" />
          </div>
          <div
            class="schneekugel"
            :class="$mq"
            v-scroll-reveal.reset="{
              delay: 1450,
            }"
          >
            <img src="@/assets/images/schneekugel.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isFixed() {
        return this.position === 'fixed'
      },
    },
    methods: {
      ytVideo() {
        EventBus.$emit('open-yt')
      },
      kinoliste() {
        EventBus.$emit('open-kinoliste')
      },
    },
  }
</script>

<style lang="scss">
  .inhalt-overview {
    display: flex;
    flex-direction: column;
    margin: 0 55px;
    margin-top: 1px;
    .inhalt {
      $self: &;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('~@/assets/images/inhalt_bg_big.jpg');
      height: 801px;
      width: 100%;
      font-family: 'Open Sans';
      padding: 0;
      &.xl,
      &.lg {
        height: 651px;
      }
      &__wrapper {
        overflow: hidden;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        font-family: 'Open Sans';
        height: 801px;
        margin: 0 55px;
        &.lg {
          margin-left: 0;
        }
        .inhalt-left {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 50%;
          margin-top: -180px;
          margin-left: 70px;
          color: white;
          .text-inhalt {
            width: 60%;
            &.lg {
              width: 70%;
            }
          }
          &.lg {
            margin-top: -150px;
            margin-left: 60px;
          }
          &.xxxxl {
            margin-left: 240px;
          }
          .middle {
            margin: 10px 0;
          }
          h1 {
            font-weight: 900;
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 22px;
            $max_font: 35px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
          p {
            font-weight: 500;
            margin: 0;
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 16px;
            $max_font: 20px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
          span {
            color: #ffeb7b;
            font-weight: 900;
          }
        }
        .inhalt-right {
          width: 50%;
          .bild-1 {
            position: absolute;
            right: 340px;
            top: 65px;
            &.xl {
              top: 140px;
              img {
                width: 65%;
              }
              right: 80px;
            }
            &.lg {
              top: 140px;
              img {
                width: 65%;
              }
              right: -120px;
            }
            &.xxxxl {
              right: 550px;
            }
          }
          .bild-2 {
            position: absolute;
            right: 520px;
            top: 443px;
            &.xl {
              top: 385px;
              img {
                width: 65%;
              }
              right: 310px;
            }
            &.lg {
              top: 383px;
              img {
                width: 65%;
              }
              right: 105px;
            }
            &.xxxxl {
              right: 730px;
            }
          }
          .bild-3 {
            position: absolute;
            right: 150px;
            top: 244px;
            &.xl {
              img {
                width: 65%;
              }
              top: 255px;
              right: 70px;
            }
            &.lg {
              img {
                width: 65%;
              }
              top: 255px;
              right: -130px;
            }
            &.xxxxl {
              right: 360px;
            }
          }
          .name-1 {
            position: absolute;
            right: 370px;
            top: 89px;
            &.xl {
              img {
                width: 55%;
              }
              top: 145px;
              right: 190px;
            }
            &.lg {
              img {
                width: 55%;
              }
              top: 145px;
              right: -5px;
            }
            &.xxxxl {
              right: 580px;
            }
          }
          .name-2 {
            position: absolute;
            right: 163px;
            top: 693px;
            &.xl {
              img {
                width: 55%;
              }
              top: 540px;
              right: 97px;
            }
            &.lg {
              img {
                width: 55%;
              }
              top: 540px;
              right: -103px;
            }
            &.xxxxl {
              right: 370px;
            }
          }
          .schneekugel {
            position: absolute;
            right: 460px;
            top: 646px;
            &.xl {
              img {
                width: 60%;
              }
              right: 340px;
              top: 520px;
            }
            &.lg {
              img {
                width: 60%;
              }
              right: 135px;
              top: 520px;
            }
            &.xxxxl {
              right: 675px;
            }
          }
        }
      }
    }
  }
</style>
