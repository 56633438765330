var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mq-layout',{attrs:{"mq":"lg+"}},[_c('div',{staticClass:"index",class:_vm.$mq,attrs:{"id":"index"}},[_c('div',{staticClass:"bg-top",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top_bg.jpg"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
        duration: 2500,
        delay: 240,
        origin: 'left',
        distance: '20px',
      }),expression:"{\n        duration: 2500,\n        delay: 240,\n        origin: 'left',\n        distance: '20px',\n      }",modifiers:{"stop":true}}],staticClass:"top-chars",class:_vm.$mq},[_c('img',{staticClass:"small-top",attrs:{"src":require("@/assets/images/top_chars.png"),"alt":""}})]),_c('div',{staticClass:"right-side",class:_vm.$mq},[_c('div',{staticClass:"right-side-content"},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            delay: 1550,
          }),expression:"{\n            delay: 1550,\n          }",modifiers:{"stop":true}}],staticClass:"packshot-buttons",class:_vm.$mq},[_c('div',{staticClass:"packshot",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/packshot.png"),"alt":""}})])]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            delay: 1550,
          }),expression:"{\n            delay: 1550,\n          }",modifiers:{"stop":true}}],staticClass:"über",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/über.png"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            duration: 3000,
            delay: 250,
            origin: 'top',
            distance: '40px',
          }),expression:"{\n            duration: 3000,\n            delay: 250,\n            origin: 'top',\n            distance: '40px',\n          }",modifiers:{"stop":true}}],staticClass:"desktop-top__tt",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/" + _vm.$config.tt),"alt":""}})]),_c('div',{staticClass:"desktop-top__trailer",class:_vm.$mq},[(_vm.$config.youtube.display)?_c('div',[_c('div',{staticClass:"open-trailer",staticStyle:{"position":"relative"},on:{"click":_vm.ytVideo}},[_c('video',{attrs:{"src":require("@/assets/videos/sizzle.mp4"),"loop":"","muted":"","autoplay":""},domProps:{"muted":true}}),_c('div',{staticClass:"trailer-misc"},[_c('img',{staticClass:"play-btn",style:([
                    _vm.ratio < 1.73
                      ? {
                          width: '40px',
                        }
                      : {
                          width: '95px',
                        } ]),attrs:{"src":require("@/assets/images/trailer/play.png"),"alt":""}}),_c('div',{staticClass:"trailer-text"},[_vm._v("Trailer ansehen")])])])]):_vm._e()]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            delay: 1650,
          }),expression:"{\n            delay: 1650,\n          }",modifiers:{"stop":true}}],staticClass:"von",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/von.png"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            delay: 1650,
          }),expression:"{\n            delay: 1650,\n          }",modifiers:{"stop":true}}],staticClass:"buttons-wrapper",class:_vm.$mq},[_c('div',{staticClass:"amazon",class:_vm.$mq},[_c('a',{attrs:{"href":"https://www.amazon.de/Alles-bester-Ordnung-Joachim-Krol/dp/B0B58M82DC/ref=sr_1_2?keywords=alles+in+bester+ordnung&qid=1663060487&sprefix=alles+in+best%2Caps%2C114&sr=8-2","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/top/amazon.png"),"alt":""}})])]),_c('div',{staticClass:"prime",class:_vm.$mq},[_c('a',{attrs:{"href":"https://www.amazon.de/Alles-bester-Ordnung-Corinna-Harfouch/dp/B0B28398K4/ref=sr_1_1?keywords=alles+in+bester+ordnung&qid=1663060454&sprefix=alles+in+best%2Caps%2C114&sr=8-1","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/top/prime.png"),"alt":""}})])])])])]),_c('BaseKinostart',{staticClass:"desktop-kinostart",attrs:{"kinostart":_vm.kinostart}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }