<!-- @format -->
<template>
  <touch-ripple
    :speed="rippleSpeed"
    :opacity="rippleOpacity"
    :color="rippleColor"
    transition="ease"
  >
    <div class="base-btn" :style="buttonStyle">{{ text }}</div>
  </touch-ripple>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: 'Press Me!',
      },
      backgroundColor: {
        type: String,
        default: '#000',
      },
      color: {
        type: String,
        default: '#fff',
      },
      rippleColor: {
        type: String,
        default: '#fff',
      },
      rippleOpacity: {
        type: Number,
        default: 0.3,
      },
      rippleSpeed: {
        type: Number,
        default: 1,
      },
    },
    computed: {
      buttonStyle() {
        return `background-color: #${this.backgroundColor};
                    color: #${this.color}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .base-btn {
    border-radius: 7px;
    padding: 5px 15px;
  }
</style>
