<!-- @format -->
<template lang="html">
  <div>
    <div class="ng-cb"></div>
  </div>
</template>

<script>
  export default {
    created() {
      let tmp = document.createElement('script')
      tmp.setAttribute('src', './ng-cb/ng-cb.js')
      document.head.appendChild(tmp)
    },
  }
</script>
