<!-- @format -->

<template>
  <footer class="footer" :class="$mq">
    <div class="footer__content">
      <div class="geschirr" :class="$mq">
        <img src="@/assets/images/footer_geschirr.png" alt="" />
      </div>
      <div class="footer__tt" :class="$mq">
        <img :src="require(`@/assets/images/` + $config.tt_small)" alt="" />
      </div>
      <div class="footer__kinostart-footer" :class="$mq">
        ab 17.11. auf DVD & Video-on-Demand<br />
        <span :class="$mq">ab 08.11. als digitaler Download</span>
      </div>
      <!-- SECTION Footer Company -> Chooses Footer by $config.verantwortlich -> look @ computed -->
      <component
        class="footer__company"
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>
    </div>
    <div class="footer__billing" :class="$mq">
      <img src="@/assets/images/billing.svg" alt="" />
    </div>
    <div class="cb-restore"></div>
  </footer>
</template>

<script>
export default {
  props: {
    billing: {
      type: Boolean,
      default: true,
    },
    youtubenote: {
      type: Boolean,
      default: true,
    },
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
    teilnahmebedingung: {
      type: String,
      default: 'leer',
    },
  },
  computed: {
    companyFooter() {
      return () =>
        import(
          `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
        )
    },
    companySocial() {
      return () =>
        import(
          `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
        )
    },
  },
}
</script>

<style lang="scss">
.footer {
  $self: &;
  z-index: 5;
  position: relative;
  text-align: center;
  height: 782px;
  font-size: 0.875rem;
  width: 100%;
  padding-bottom: 5rem;
  background-color: white;
  &.sm {
    padding-bottom: 0;
    background-color: white;
    height: 540px;
  }
  &.md {
    padding-bottom: 0;
    background-color: white;
    height: 700px;
  }
  &.xl {
    height: 700px;
  }
  &.lg {
    height: 750px;
  }
  &.xxxl,
  &.xxxxl {
    height: 1000px;
  }

  &__content {
    position: relative;
    margin: 0 auto;
    padding-top: 2rem;
    .geschirr {
      width: 100%;
      img {
        width: 23%;
      }
      &.md,
      &.sm {
        display: none;
      }
      &.lg {
        img {
          width: 40%;
        }
      }
    }
    .bild-crew {
      position: absolute;
      top: -257px;
      z-index: 100;
      left: 50%;
      margin-left: -440px;
      &.sm,
      &.md {
        display: none;
      }
    }
    #{$self}__tt {
      margin: 40px auto 10px auto;
      img {
        width: 22%;
      }
      &.lg {
        img {
          width: 40%;
        }
      }
      &.sm,
      &.md {
        img {
          width: 70%;
        }
        padding: 0 5%;
        margin: 10px auto 0 auto;
      }
    }
    #{$self}__kinostart-footer {
      line-height: 1;
      color: black;
      text-transform: uppercase;
      padding: 0 0 15px 0;
      margin-top: 0;
      font-family: 'Open Sans';
      span {
        margin-top: 10px;
        font-weight: 600;
        &.lg,
        &.xl,
        &.xxl,
        &.xxxl,
        &.xxxxl {
          font-size: 34px !important;
          font-weight: 600;
        }
        &.sm {
          font-size: 16px !important;
        }
        &.md {
          font-size: 32px !important;
        }
      }
      &.lg,
      &.xl,
      &.xxl,
      &.xxxl,
      &.xxxxl {
        font-weight: 900;
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 37px;
        $max_font: 40px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.sm {
        font-size: 18px;
        font-weight: 900;
      }
      &.md {
        font-size: 36px;
        padding: 0 0 10px 0;
        font-weight: 900;
      }
    }
  }
  #{$self}__billing {
    margin-top: 15px;
    &.sm,
    &.md {
      margin-top: 10px;
    }
    &.sm,
    &.md,
    &.lg,
    &.xl {
      padding: 0 5%;
    }
    &.xxl,
    &.xxxl,
    &.xxxxl {
      img {
        max-width: 1000px;
      }
    }
  }
}
</style>
