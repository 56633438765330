<template>
  <div id="desktop-fotos" ref="fotos" class="desktop-fotos" :class="$mq">
    <BaseCarousel
      :autoplay="false"
      :preview="true"
      :fade="true"
      :fullscreen="true"
      :dots="false"
    >
      <div class="source">
        <div
          v-for="value in this.$config.gallery.count"
          :key="value"
          class="carousel-cell"
        >
          <div class="fotos-caro" :class="$mq">
            <img
              :src="require(`@/assets/images/gallery/` + value + `.png`)"
              alt
              class="pictures"
            />
          </div>
          <div class="fotos-caro-ipad" :class="$mq">
            <img
              :src="
                require(`@/assets/images/gallery/ipad_pictures/` +
                  value +
                  `.jpg`)
              "
              alt
              class="pictures-ipad"
            />
          </div>
        </div>
      </div>
    </BaseCarousel>
  </div>
</template>
<script>
  export default {
    /*    props: {
      gallery: {
        type: Boolean,
        default: false,
      },
    },*/
    data() {
      return {
        index: null,
        foto: null,
        images: [],
        windowWidth: window.innerWidth,
      }
    },
    methods: {
      openGallery(number) {
        this.index = number
      },
    },
    mounted() {
      /* for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 1) +
          `.jpg`)
      } */
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    },
  }
</script>

<style lang="scss">
  .desktop-fotos {
    border-top: 3px solid white;
    border-bottom: 3px solid white;
    background-color: white;
    z-index: 3;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: auto;
    .fotos-caro {
      margin: 0 55px;
      &.lg {
        display: none;
      }
    }
    .fotos-caro-ipad {
      margin: 0 55px;
      &.xl,
      &.xxl,
      &.xxxl,
      &.xxxxl {
        display: none;
      }
    }
    .flickity-page-dots {
      margin-left: 280px;
    }
    .flickity-page-dots .dot.is-selected {
      background-color: #ffdb15;
    }
    .flickity-prev-next-button.previous {
      left: 0;
      border-right: 6px solid white;
      border-top: 6px solid white;
      border-bottom: 6px solid white;
    }
    .flickity-prev-next-button {
      border: 0;
      z-index: 33333;
    }
    .flickity-prev-next-button.next {
      right: 0;
      border-left: 6px solid white;
      border-top: 6px solid white;
      border-bottom: 6px solid white;
    }
    .flickity-prev-next-button {
      width: 85px;
      height: 125px;
      border-radius: 0;
    }
    .flickity-fullscreen-button {
      display: none;
    }
    .flickity-prev-next-button .flickity-button-icon {
      position: absolute;
      left: 20%;
      top: 20%;
      width: 60%;
      height: 60%;
    }
    .flickity-button-icon:hover {
      fill: #f8c31f;
    }
    .flickity-prev-next-button,
    .flickity-prev-next-button:hover {
      background-color: #ffdb15;
    }
    .column {
      float: left;
      width: 33.2%;
      overflow: hidden;
      img,
      video {
        cursor: pointer;
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: transform 1s ease-out;
        -moz-transition: transform 1s ease-out;
        -ms-transition: transform 1s ease-out;
        transition: transform 1s ease-out;

        &:hover {
          -moz-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          -o-transform: scale(1.05);
          -ms-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }
  }
</style>
