<!-- @format -->

<template>
  <div class="crew" :class="$mq" id="crew">
    <div class="crew-content" :class="$mq">
      <div class="crew-wrapper" :class="$mq">
        <div class="crew-left" :class="$mq">
          <div
            class="bild-4"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 250,
              origin: 'left',
              distance: '40px',
              viewOffset: {
                top: -500,
                bottom: 600,
              },
            }"
          >
            <img src="@/assets/images/bild_4.png" alt="" />
          </div>
          <div
            class="bild-5"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 350,
              origin: 'left',
              distance: '40px',
              viewOffset: {
                top: -500,
                bottom: 600,
              },
            }"
          >
            <img src="@/assets/images/bild_5.png" alt="" />
          </div>
          <div
            class="bild-6"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 450,
              origin: 'left',
              distance: '40px',
              viewOffset: {
                top: -500,
                bottom: 600,
              },
            }"
          >
            <img src="@/assets/images/bild_6.png" alt="" />
          </div>
          <div
            class="saftpresse"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 550,
              origin: 'left',
              distance: '40px',
              viewOffset: {
                top: -500,
                bottom: 600,
              },
            }"
          >
            <img src="@/assets/images/saftpresse.png" alt="" />
          </div>
        </div>
        <div class="crew-left-lg" :class="$mq">
          <div
            class="bild-4"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 250,
              origin: 'left',
              distance: '40px',
              viewOffset: {
                top: -250,
                bottom: 300,
              },
            }"
          >
            <img src="@/assets/images/bild_4.png" alt="" />
          </div>
          <div
            class="bild-5"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 350,
              origin: 'left',
              distance: '40px',
              viewOffset: {
                top: -250,
                bottom: 300,
              },
            }"
          >
            <img src="@/assets/images/bild_5.png" alt="" />
          </div>
          <div
            class="bild-6"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 450,
              origin: 'left',
              distance: '40px',
              viewOffset: {
                top: -250,
                bottom: 300,
              },
            }"
          >
            <img src="@/assets/images/bild_6.png" alt="" />
          </div>
          <div
            class="saftpresse"
            :class="$mq"
            v-scroll-reveal.reset="{
              duration: 1000,
              delay: 550,
              origin: 'left',
              distance: '40px',
              viewOffset: {
                top: -250,
                bottom: 300,
              },
            }"
          >
            <img src="@/assets/images/saftpresse.png" alt="" />
          </div>
        </div>
        <div class="crew-right" :class="$mq">
          <div class="crew-right-content" :class="$mq">
            <div
              class="top-crew"
              v-scroll-reveal.reset="{
                duration: 1000,
                delay: 250,
                origin: 'bottom',
                distance: '40px',
                viewOffset: {
                  top: -500,
                  bottom: 600,
                },
              }"
            >
              <h1 :class="$mq">Besetzung</h1>
              <div class="top-crew-content">
                <p><span>Corinna Harfouch </span>Marlen</p>
                <p><span>Daniel Sträßer </span>Fynn</p>
                <p><span>Luise Kinner </span>Lea</p>
                <p><span>Simon Hatzl </span>Hausverwalter</p>
                <p><span>Steffen Will </span>Möbelverkäufer</p>
                <p><span>Felix Vörtler </span>Winfried Rehberger</p>
                <p class="crew-cut" :class="$mq">
                  <span>Katharina Schmalenberg </span>Andrea Halbleib
                </p>
                <p><span>Susanne Bredehöft </span>Kioskbesitzerin</p>
                <p>u.v.a.</p>
                <p class="crew-cut" :class="$mq">
                  als Gast:<span> Joachim Król</span> in der Rolle des Magnus
                  Joosten
                </p>
              </div>
            </div>
            <div
              class="bottom-crew"
              v-scroll-reveal.reset="{
                duration: 1000,
                delay: 350,
                origin: 'bottom',
                distance: '40px',
                viewOffset: {
                  top: -550,
                  bottom: 450,
                },
              }"
            >
              <h1 :class="$mq">Stab</h1>
              <div class="bot-crew">
                <p>Regie<span> Natja Brunckhorst</span></p>
                <p class="crew-cut" :class="$mq">
                  Drehbuch<span> Natja Brunckhorst & Martin Rehbock</span>
                </p>
                <p>Kamera<span> Niklas Lindschau</span></p>
                <p>Szenenbild<span> Zazie Knepper</span></p>
                <p>Musik<span> Lambert</span></p>
                <p>Schlusssong<span> ERDMÖBEL</span></p>
                <p>Produzent<span> Joachim Ortmanns</span></p>
                <p>Producer<span> Lino Rettinger</span></p>
                <p>Redaktion WDR<span> Andrea Hanke</span></p>
                <p>Redaktion ARTE<span> Barbara Häbe</span></p>
                <p>Redaktion SWR<span> Stefanie Groß</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
export default {
  props: {},
}
</script>

<style lang="scss">
.crew {
  display: flex;
  flex-direction: column;
  margin: 0 55px;
  .crew-content {
    $self: &;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('~@/assets/images/cast_bg.jpg');
    height: 801px;
    width: 100%;
    font-family: 'Open Sans';
    padding: 0;
    &.xl,
    &.lg {
      height: 739px;
    }
    .crew-wrapper {
      overflow: hidden;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      font-family: 'Open Sans';
      height: 799px;
      margin: 0 55px;
      &.lg {
        height: 709px;
      }
      .crew-left-lg {
        width: 50%;
        .bild-4 {
          position: absolute;
          left: 25px;
          top: 65px;
          img {
            width: 100%;
          }
          &.xl {
            top: 125px;
            left: 60px;
            img {
              width: 70%;
            }
          }
          &.lg {
            top: 125px;
            left: 10px;
            img {
              width: 70%;
            }
          }
          &.xxxxl {
            left: 285px;
          }
        }
        .bild-5 {
          position: absolute;
          left: 490px;
          top: 294px;
          img {
            width: 100%;
          }
          &.xl {
            left: 345px;
            top: 284px;
            img {
              width: 72%;
            }
          }
          &.lg {
            left: 295px;
            top: 284px;
            img {
              width: 72%;
            }
          }

          &.xxxxl {
            left: 750px;
          }
        }
        .bild-6 {
          position: absolute;
          left: 110px;
          top: 442px;
          img {
            width: 100%;
          }
          &.xl {
            top: 390px;
            left: 90px;
            img {
              width: 67%;
            }
          }
          &.lg {
            top: 390px;
            left: 40px;
            img {
              width: 67%;
            }
          }
          &.xxxxl {
            left: 365px;
          }
        }
        .saftpresse {
          position: absolute;
          left: 295px;
          top: 580px;
          img {
            width: 100%;
          }
          &.xl {
            left: 200px;
            top: 475px;
            img {
              width: 75%;
            }
          }
          &.lg {
            left: 150px;
            top: 475px;
            img {
              width: 75%;
            }
          }
          &.xxxxl {
            left: 550px;
          }
        }
        &.xl,
        &.xxl,
        &.xxxl,
        &.xxxxl {
          display: none;
        }
      }
      .crew-left {
        width: 50%;
        &.lg {
          display: none;
        }
        .bild-4 {
          position: absolute;
          left: 25px;
          top: 65px;
          img {
            width: 100%;
          }
          &.xl {
            top: 125px;
            left: 60px;
            img {
              width: 70%;
            }
          }
          &.lg {
            top: 125px;
            left: 10px;
            img {
              width: 70%;
            }
          }
          &.xxxxl {
            left: 285px;
          }
        }
        .bild-5 {
          position: absolute;
          left: 490px;
          top: 294px;
          img {
            width: 100%;
          }
          &.xl {
            left: 345px;
            top: 284px;
            img {
              width: 72%;
            }
          }
          &.lg {
            left: 295px;
            top: 284px;
            img {
              width: 72%;
            }
          }

          &.xxxxl {
            left: 750px;
          }
        }
        .bild-6 {
          position: absolute;
          left: 110px;
          top: 442px;
          img {
            width: 100%;
          }
          &.xl {
            top: 390px;
            left: 90px;
            img {
              width: 67%;
            }
          }
          &.lg {
            top: 390px;
            left: 40px;
            img {
              width: 67%;
            }
          }
          &.xxxxl {
            left: 365px;
          }
        }
        .saftpresse {
          position: absolute;
          left: 295px;
          top: 580px;
          img {
            width: 100%;
          }
          &.xl {
            left: 200px;
            top: 475px;
            img {
              width: 75%;
            }
          }
          &.lg {
            left: 150px;
            top: 475px;
            img {
              width: 75%;
            }
          }
          &.xxxxl {
            left: 550px;
          }
        }
      }
      .crew-right {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 190px;
        width: 50%;
        &.xl {
          margin-top: -120px;
        }
        &.lg {
          margin-left: 280px;
        }
        .crew-right-content {
          .crew-cut {
            &.lg {
              width: 50%;
            }
          }
          p {
            font-weight: 500;
            color: white;
            margin: 0;
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 16px;
            $max_font: 20px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
          h1 {
            color: #ffeb7b;
            margin: 10px 0;
            font-weight: 900;
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 22px;
            $max_font: 35px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            &.lg {
              margin: 0;
            }
          }
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
