<!-- @format -->
<template>
  <div class="base-kinostart" :class="$mq">
    <!-- eslint-disable-next-line -->
    <div class="text" :class="$mq" v-html="kinostart"></div>
  </div>
</template>
<script>
export default {
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
  },
}
</script>
<style lang="scss">
.base-kinostart {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #dd4f0f;
  font-weight: 900;
  font-family: 'Open Sans';
  padding: 10px 0;
  line-height: 0.8;
  span {
    font-weight: 500;
    font-size: 40px;
  }
  &.lg {
    span {
      font-size: 25px;
    }
  }
  &.sm,
  &.md {
    padding-top: 10px;
    margin: 0;
    background-color: white;
  }
}
</style>
