<!-- @format -->
<template>
  <div id="basecarousel" class="carousel">
    <slot></slot>
  </div>
</template>

<script>
  import Flickity from 'flickity'
  import 'flickity-fullscreen'
  import 'flickity-fade'
  import 'flickity/dist/flickity.min.css'
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      wraparound: { type: Boolean, default: true },
      autoplay: { type: Boolean, default: false },
      preview: { type: Boolean, default: false },
      fullscreen: { type: Boolean, default: false },
      fade: { type: Boolean, default: false },
      dots: { type: Boolean, default: false },
    },
    computed: {
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
    },
    mounted() {
      window.addEventListener('load', () => {
        window.dispatchEvent(new Event('resize'))
      })
      // eslint-disable-next-line
    let flkty = new Flickity('.source', {
        wrapAround: this.wraparound,
        autoPlay: this.autoplay,
        fullscreen: this.fullscreen,
        fade: this.fade,
        pageDots: this.dots,
        setGallerySize: true,
        imagesLoaded: true,
      })
    },
  }
</script>

<style lang="scss">
  .flickity-page-dots {
    position: relative;
    bottom: 0;
    background-color: #fff;
    padding-bottom: 10px;
    padding: 5px 0;
    .dot.is-selected {
      background-color: black;
    }
  }

  .carousel {
    /* height: 300px; */
  }

  .carousel-cell {
    width: 100%;
  }

  .flickity-button {
    position: absolute;
  }

  /* icon color */
  .flickity-button-icon {
    fill: white;
  }
  .flickity-button-icon:hover {
    fill: black;
  }
  /* no circle */
  .flickity-prev-next-button,
  .flickity-prev-next-button:hover {
    background-color: #b2b3b6;
  }

  .flickity-prev-next-button {
    width: 7%;
    height: 13%;
    border-radius: 0;
  }

  .flickity-prev-next-button.previous {
    left: 40px;
  }

  .flickity-prev-next-button.next {
    right: 40px;
  }

  .preview-item {
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      opacity: 0.7;
    }
  }

  .is-selected,
  .preview-item:hover {
    img {
      -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
      filter: grayscale(0);
      opacity: 1;
    }
  }
  /* Flickity fullscreen v1.0.1
------------------------- */

  .flickity-enabled.is-fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 99999;
    .flickity-prev-next-button {
      padding-bottom: -20px !important;
    }
    .pictures {
      border: none;
      width: 100%;
    }
  }

  .flickity-prev-next-button {
    top: 50%;
  }

  .flickity-enabled.is-fullscreen .flickity-page-dots {
    bottom: 10px;
  }

  .flickity-enabled.is-fullscreen .flickity-page-dots .dot {
    background: white;
  }

  /* prevent page scrolling when flickity is fullscreen */
  html.is-flickity-fullscreen {
    overflow: hidden;
  }

  /* ---- flickity-fullscreen-button ---- */

  .flickity-fullscreen-button {
    position: absolute;
    display: block;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    //background-color: rgba(255, 255, 255, 0.6);
  }

  /* right-to-left */
  .flickity-rtl .flickity-fullscreen-button {
    right: auto;
    left: 10px;
  }

  .flickity-fullscreen-button-exit {
    display: none;
  }

  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit {
    display: block;
  }
  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-view {
    display: none;
  }

  .flickity-fullscreen-button .flickity-button-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 4px;
    top: 4px;
  }
  @media (max-width: 960px) {
    .flickity-prev-next-button {
      width: 40px;
      height: 80px;
    }
    .flickity-prev-next-button.previous {
      left: 0px;
    }

    .flickity-prev-next-button.next {
      right: 0px;
    }

    .flickity-fullscreen-button {
      display: none;
    }
  }
</style>
