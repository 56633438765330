<!-- @format -->

<template>
  <footer
    class="w-full lg:h-footer bg-footer-mobile lg:bg-footer pb-20 lg:pb-0"
  >
    <div class="flex flex-col lg:justify-center items-center h-full pb-3">
      <div class="flex flex-col mt-2 items-center footer-top">
        <div class="lg:px-20 mb-6 flex justify-center">
          <img
            class="w-4/5 object-contain"
            src="~@/assets/images/billing.png"
            alt=""
          />
        </div>
        <div
          class="flex flex-col md:flex-row items-center md:items-baseline mb-2 font-bold text-xs xl:text-sm navpoints mb-4 sm:mb-0 uppercase"
        >
          <a href="https://port-prince.de/" target="_blank"
            >Port Au Prince Home</a
          >
          <a href="https://port-prince.de/impressum/" target="_blank"
            >IMPRESSUM</a
          >
          <a
            href="https://port-prince.de/datenschutzerklaerung/"
            target="_blank"
            >DATENSCHUTZ</a
          >
        </div>
        <div class="relative text-xxs text-black font-normal">
          &copy; 2019 Port-au-Prince. Alle Rechte vorbehalten. made by
          <a
            class="text-gray-900 hover:text-bluenormal"
            href="http://www.novagraphix.de"
            target="_blank"
            >novagraphix</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    props: {
      social: {
        type: Boolean,
        default: true,
      },
      billing: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      company: {
        type: String,
        default: 'port_au_prince',
      },
    },
  }
</script>

<style lang="scss"></style>
