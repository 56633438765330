<!-- @format -->
<!-- @format -->
<template>
  <div class="mob-trailer">
    <a
      v-if="$config.youtube.display === true"
      :href="$config.youtube.link"
      target="_blank"
      class="m-trailer external"
    >
      <div class="m-trailer__wrapper">
        <video
          src="@/assets/videos/sizzle.mp4"
          loop
          muted
          autoplay
          playsinline
        ></video>
        <div class="m-trailer__text-wrapper">
          <img src="@/assets/images/trailer/play.png" alt="" />
        </div>
      </div>

      <p class="m-trailer__note">
        Quelle: Youtube / Zum Ansehen des Videos müssen Sie auf den Play Button
        klicken. Wir möchten Sie darauf hinweisen, dass nach der Aktivierung
        Daten an Youtube übermittelt werden.
      </p>
    </a>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .m-trailer {
    position: relative;
    z-index: 899;
    $self: &;
    background-color: #ffec82;
    display: block;
    padding-bottom: 20px;
    &__wrapper {
      margin: 0% 5% 0% 5%;
      position: relative;
      cursor: pointer;
      border: 8px solid white;
      video {
        width: 100%;
      }
      #{$self}__text-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 111;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 20%;
        }
        #{$self}__text {
          text-align: center;
          font-weight: bold;
          color: white;
          margin-top: 1rem;
          font-size: 25px;
          position: absolute;
          bottom: 10px;
          left: 10px;
        }
      }
    }
    &__note {
      margin: 1rem 5% 0 5%;
      text-align: center;
      font-size: 14px;
      color: black;
      font-weight: 500;
      font-family: 'Open Sans';
      &.sm {
        font-size: 0.5rem;
      }
    }
  }
</style>
