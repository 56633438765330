<!-- @format -->
<template>
  <div>
    <gallery
      v-if="$config.gallery.display"
      class="hidden"
      :images="images"
      :index="index"
      @close="$parent.index = null"
    >
      <template v-slot:prev>
        <img src="@/assets/images/gallery/prev.png" />
      </template>
      <template v-slot:next>
        <img src="@/assets/images/gallery/next.png" />
      </template>
      <template v-slot:close>
        <img src="@/assets/images/gallery/exit.png" />
      </template>
    </gallery>
  </div>
</template>

<script>
  import VueGallery from 'vue-gallery'

  export default {
    components: {
      gallery: VueGallery,
    },
    props: {
      index: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        images: [],
      }
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          (index + 1) +
          `.jpg`)
      }
    },
  }
</script>

<style lang="scss">
  #blueimp-gallery {
    background-color: rgba(0, 0, 0, 0.9);
  }
  #blueimp-gallery .prev {
    border-radius: 0;
    border: 0;
    width: 62px;
    height: 151px;
    left: 0;
    text-shadow: 0;
  }
  #blueimp-gallery .next {
    border-radius: 0;
    border: 0;
    width: 62px;
    height: 151px;
    right: 0;
  }
  #blueimp-gallery .close {
    border: 0;
    right: 0;
    top: 0;
    width: 62px;
    height: 45px;
  }
</style>
