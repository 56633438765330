<!-- @format -->

<template>
  <flickity ref="flickity" :options="flickityOptions">
    <div v-for="cite in $config.cites" :key="cite.text" class="carousel-cell">
      <cite class="base-cite" :class="$mq">
        <p :class="$mq" class="base-cite__text" v-text="cite.text" />
        <p :class="$mq" class="base-cite__author" v-text="cite.author" />
      </cite>
    </div>
  </flickity>
</template>

<script>
import Flickity from 'vue-flickity'
require('flickity-fade')

export default {
  components: {
    Flickity,
  },
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        autoPlay: true,
        fade: true,
        draggable: false,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.carousel-cell {
  width: 660px !important;
}
.base-cite {
  $self: &;
  width: 1200px !important;
  z-index: 999;
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: #dd4f0f;
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin: 0 auto;
    text-align: center;
    width: 1200px !important;
    font-size: 32px;
    margin-left: -265px;
    margin-top: 30px;
    &.sm {
      margin-left: 160px;
      font-size: 20px;
      width: 350px !important;
      line-height: 1.6rem;
    }
    &.md {
      margin-left: -35px;
      font-size: 30px;
      width: 750px !important;
    }
    &.lg {
      margin-left: -40px;
      width: 750px !important;
    }
  }
  &__author {
    font-style: normal;
    text-align: center;
    font-size: 24px;
    color: black;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 40px;
    &.sm {
      font-size: 14px;
      margin-bottom: 0px;
    }
    &.md {
      font-size: 18px;
    }
  }
}
</style>
