<!-- @format -->

<template>
  <div class="musik" :class="$mq" id="musik">
    <div class="musik-content" :class="$mq">
      <div class="musik-top">
        <div class="musik-top-left">
          <div class="musik-top-left-image" :class="$mq">
            <img src="@/assets/images/erdmoebel.png" alt="" />
          </div>
        </div>
        <div class="musik-top-right">
          <h1>Erdmöbel</h1>
          <p><b>„Das Vakuum”</b> ist der Schlusssong im Film</p>
          <span>Alle Infos unter:</span>
          <div class="erdmoebel_button" :class="$mq">
            <a href="https://erdmoebel.de/" target="_blank"
              ><img src="@/assets/images/erdmoebel_button.png" alt=""
            /></a>
          </div>
        </div>
      </div>
      <div class="musik-bottom" :class="$mq">
        <div class="musik-bottom-right">
          <div class="musik-bottom-right-image" :class="$mq">
            <img src="@/assets/images/aibo_desktop.png" alt="" />
          </div>
        </div>
        <div class="musik-bottom-left">
          <h1>Lambert</h1>
          <p class="musik-bottom-text">
            <b>Der Soundtrack zum Film</b>
            von<br />
            Ausnahmemusiker Lambert<br />
          </p>
          <span>Jetzt hören auf:</span><br />
          <div class="spotify_button" :class="$mq">
            <a
              href="https://open.spotify.com/album/5ONcj3IsEHUaimUyQeYM7C?si=2Vlsv5FPTkSgVJXbLCHK5A"
              target="_blank"
            >
              <img src="@/assets/images/spotify.png" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
export default {
  props: {},
}
</script>

<style lang="scss">
.musik {
  background-color: white;
  padding: 45px;
  padding-top: 0;
  .musik-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    h1 {
      margin-bottom: 0;
      font-weight: 900;
      color: #dd4f0f;
      $min_width: 1024px;
      $max_width: 2560px;
      $min_font: 30px;
      $max_font: 45px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
    p {
      margin-top: 0;
      margin-bottom: 9px;
      width: 42%;
      $min_width: 1024px;
      $max_width: 2560px;
      $min_font: 16px;
      $max_font: 20px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
    b {
      font-weight: 900;
    }
    span {
      $min_width: 1024px;
      $max_width: 2560px;
      $min_font: 16px;
      $max_font: 20px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
      color: #dd4f0f;
    }
    .musik-top-right {
      width: 50%;
    }
    .musik-top-left {
      width: 50%;
      .musik-top-left-image {
        margin-right: 60px;
        margin-top: 10px;
        width: 30%;
        float: right;
        &.xl,
        &.lg {
          width: 45%;
        }
        img {
          width: 100%;
        }
      }
    }
    .erdmoebel_button {
      margin-top: 10px;
      width: 25%;
      &.xl,
      &.lg {
        width: 35%;
      }
      img {
        width: 100%;
      }
    }
  }
  .musik-bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    text-align: right;
    margin-top: 20px;
    h1 {
      margin-bottom: 0;
      font-weight: 900;
      color: #dd4f0f;
      $min_width: 300px;
      $max_width: 1023px;
      $min_font: 25px;
      $max_font: 45px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
    p {
      margin-top: 0;
      margin-bottom: 9px;
      width: 100%;
      $min_width: 1024px;
      $max_width: 2560px;
      $min_font: 16px;
      $max_font: 20px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
    b {
      font-weight: 900;
    }
    span {
      color: #dd4f0f;
      $min_width: 1024px;
      $max_width: 2560px;
      $min_font: 16px;
      $max_font: 20px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
    .musik-bottom-left {
      width: 50%;
    }
    .spotify_button {
      margin-top: 10px;
      width: 18%;
      float: right;
      &.xl,
      &.lg {
        width: 26%;
      }
      img {
        width: 100%;
      }
    }
    .musik-bottom-right {
      width: 50%;
      .musik-bottom-right-image {
        margin-left: 60px;
        margin-top: 20px;
        width: 30%;
        &.xl,
        &.lg {
          width: 45%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
