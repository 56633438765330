<!-- @format -->

<template>
  <div class="bg-secondary border-t-8 border-b-8 border-white pt-8">
    <div
      v-for="mobileCite in $config.cites"
      :key="mobileCite.author"
      class="px-4 md:px-8 pb-10"
    >
      <cite class="text-center text-black not-italic">
        <p
          class="text-xl md:text-2xl font-extrabold leading-snug"
          v-text="mobileCite.text"
        />
        <p
          class="uppercase text-sm font-light mt-2"
          v-text="mobileCite.author"
        />
      </cite>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
