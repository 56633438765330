/** @format */

import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1600,
    xxl: 1921,
    xxxl: 2343,
    xxxxl: '2560',
  },
})
