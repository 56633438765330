<!-- @format -->
<template>
  <div class="modal-youtube" v-if="youtube.display && isOpen">
    <div class="wrapper">
      <window-close @click="close" class="close" />
      <component
        :is="layout"
        :url="url"
        :margin="margin"
        :height="ytHeight"
        :width="ytWidth"
      />
    </div>
  </div>
</template>

<script>
  import WindowClose from 'vue-material-design-icons/WindowClose.vue'
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    components: {
      WindowClose,
      dummy: () => import('@/components/Base/Youtube/Dummy'),
      ytiframe: () => import('@/components/Base/Youtube/Ytiframe'),
    },
    data() {
      return {
        isOpen: false,
        real: false,
      }
    },
    props: {
      youtube: {
        type: Object,
        default: () => {},
      },
      margin: {
        type: Number,
        default: 100,
      },
    },
    computed: {
      layout() {
        return this.real ? 'ytiframe' : 'dummy'
      },
      url() {
        return `https://www.youtube-nocookie.com/embed/${this.youtube.id}?controls=0&rel=0&autoplay=1`
      },
      ytHeight() {
        return this.$store.getters['Window/isSize'].height - 2 * this.margin
      },
      ytWidth() {
        return this.$store.getters['Window/isSize'].width - 2 * this.margin
      },
    },
    mounted() {
      this.$nextTick(function () {
        /* eslint-disable-next-line no-undef */
        EventBus.$on(
          'open-yt',
          function () {
            this.isOpen = true
          }.bind(this)
        )
        EventBus.$on(
          'open-real',
          function () {
            this.real = true
          }.bind(this)
        )
      })
    },
    methods: {
      close() {
        this.autoplay = 0
        this.isOpen = false
      },
    },
  }
</script>

<style lang="scss">
  .modal-youtube {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      color: white;
      font-size: 2rem;
      width: 2rem;
      height: 2rem;
      align-self: flex-end;
      &:hover {
        color: red;
      }
    }
  }
</style>
