var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inhalt-overview",class:_vm.$mq,attrs:{"id":"inhalt"}},[_c('div',{staticClass:"inhalt desktop-inhalt",class:_vm.$mq,attrs:{"id":"desktop-inhalt"}},[_c('div',{staticClass:"inhalt__wrapper",class:_vm.$mq},[_c('div',{staticClass:"inhalt-left",class:_vm.$mq},[_c('h1',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
            duration: 1000,
            delay: 250,
            origin: 'bottom',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 250,\n            origin: 'bottom',\n            distance: '40px',\n          }",modifiers:{"reset":true}}]},[_vm._v(" Eine feinsinnige Komödie über"),_c('br'),_vm._v(" das Zuviel und das Zuwenig. ")]),_c('div',{staticClass:"text-inhalt",class:_vm.$mq},[_c('p',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
              duration: 1000,
              delay: 350,
              origin: 'bottom',
              distance: '40px',
            }),expression:"{\n              duration: 1000,\n              delay: 350,\n              origin: 'bottom',\n              distance: '40px',\n            }",modifiers:{"reset":true}}]},[_vm._v(" Marlen ist früher durch die Welt gereist, draußen strahlt sie, aber ihre Wohnung hat sie mit Gegenständen vollgestellt. Sie ist eine leidenschaftliche Sammlerin. Alles ist zu wertvoll, zu bedeutend, um es wegzuschmeißen. Fynn dagegen will mit nur 100 Dingen durch die Welt gehen. ")]),_c('p',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
              duration: 1000,
              delay: 450,
              origin: 'bottom',
              distance: '40px',
            }),expression:"{\n              duration: 1000,\n              delay: 450,\n              origin: 'bottom',\n              distance: '40px',\n            }",modifiers:{"reset":true}}],staticClass:"middle"},[_c('span',[_vm._v(" »Ordnung ist das halbe Leben!«,")]),_vm._v(" sagt er. "),_c('span',[_vm._v("»Willkommen in der anderen Hälfte!«,")]),_vm._v(" antwortet sie. ")]),_c('p',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
              duration: 1000,
              delay: 550,
              origin: 'bottom',
              distance: '40px',
            }),expression:"{\n              duration: 1000,\n              delay: 550,\n              origin: 'bottom',\n              distance: '40px',\n            }",modifiers:{"reset":true}}]},[_vm._v(" Das ist der Humor und der Kampfstil, den sie von jetzt an pflegen. Marlen und Fynn – stärker könnten die Gegensätze nicht sein. Doch Gegensätze ziehen sich ja bekanntlich an … ")])])]),_c('div',{staticClass:"inhalt-right",class:_vm.$mq},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
            duration: 1000,
            delay: 250,
            origin: 'right',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 250,\n            origin: 'right',\n            distance: '40px',\n          }",modifiers:{"reset":true}}],staticClass:"bild-1",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/bild_1.png"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
            duration: 1000,
            delay: 250,
            origin: 'right',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 250,\n            origin: 'right',\n            distance: '40px',\n          }",modifiers:{"reset":true}}],staticClass:"name-1",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/corinna.png"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
            duration: 1000,
            delay: 650,
            origin: 'right',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 650,\n            origin: 'right',\n            distance: '40px',\n          }",modifiers:{"reset":true}}],staticClass:"bild-2",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/bild_2.png"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
            duration: 1000,
            delay: 1050,
            origin: 'right',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 1050,\n            origin: 'right',\n            distance: '40px',\n          }",modifiers:{"reset":true}}],staticClass:"bild-3",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/bild_3.png"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
            duration: 1000,
            delay: 1050,
            origin: 'right',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 1050,\n            origin: 'right',\n            distance: '40px',\n          }",modifiers:{"reset":true}}],staticClass:"name-2",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/daniel.png"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
            delay: 1450,
          }),expression:"{\n            delay: 1450,\n          }",modifiers:{"reset":true}}],staticClass:"schneekugel",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/schneekugel.png"),"alt":""}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }