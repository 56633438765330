<!-- @format -->

<template>
  <mq-layout mq="lg+">
    <header id="header" class="header" :class="$mq">
      <div class="header__content">
        <nav id="navi">
          <BaseNavigation />
        </nav>
        <nav v-if="kinofinder" id="finder">
          <BaseKinofinder v-if="$config.kinoservice.display" />
        </nav>
        <div class="right">
          <component
            :is="companySocial"
            :youtube="true"
            :instagram="true"
            :facebook="true"
            v-if="social"
            class="header__social"
          ></component>
        </div>
      </div>
    </header>
  </mq-layout>
</template>

<script>
/** @format */

export default {
  props: {
    social: {
      type: Boolean,
      default: true,
    },
    kinofinder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDefaultImage: true,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      if (window.scrollY > 100) {
        return (this.isDefaultImage = false)
      }
      if (window.scrollY <= 100) {
        if (!this.defaultImage) {
          return (this.isDefaultImage = true)
        }
      }
    },
  },
  computed: {
    companySocial() {
      return () =>
        import(
          `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
        )
    },
  },
}
</script>

<style lang="scss">
/** @format */

.header {
  $self: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background-color: white;
  z-index: 9999;
  overflow: hidden;
  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0 55px;
    height: $header-height;
    .right {
      .bg_color {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        transition: top 0.3s;
      }
    }
  }
  &__social {
    position: absolute;
    top: 0px;
    right: 45px;
    text-align: right;
  }
}
</style>
