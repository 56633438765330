<!-- @format -->

<template>
  <div class="prokino-social">
    <a class="mr-1" href="https://www.facebook.com/Prokino" target="_blank">
      <img src="@/assets/images/prokino/fb.png" alt="" />
    </a>
    <a class="mr-1" href="https://www.youtube.com/user/PROKINO" target="_blank">
      <img src="@/assets/images/prokino/yt.png" alt="" />
    </a>
    <a href="https://www.prokino.de/" target="_blank">
      <img src="@/assets/images/prokino/pk.png" alt="" />
    </a>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .prokino-social {
    $self: &;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    justify-content: center;
    a:not(:last-child) {
      margin-right: 0.25rem;
    }
  }
</style>
