<template>
  <mq-layout mq="lg+">
    <div class="index" id="index" :class="$mq">
      <div class="bg-top" :class="$mq">
        <img src="@/assets/images/top_bg.jpg" alt="" />
      </div>
      <div
        class="top-chars"
        :class="$mq"
        v-scroll-reveal.stop="{
          duration: 2500,
          delay: 240,
          origin: 'left',
          distance: '20px',
        }"
      >
        <img src="@/assets/images/top_chars.png" alt="" class="small-top" />
      </div>
      <div class="right-side" :class="$mq">
        <div class="right-side-content">
          <div
            class="packshot-buttons"
            :class="$mq"
            v-scroll-reveal.stop="{
              delay: 1550,
            }"
          >
            <div class="packshot" :class="$mq">
              <img src="@/assets/images/top/packshot.png" alt="" />
            </div>
          </div>
          <div
            class="über"
            :class="$mq"
            v-scroll-reveal.stop="{
              delay: 1550,
            }"
          >
            <img src="@/assets/images/top/über.png" alt="" />
          </div>
          <div
            class="desktop-top__tt"
            :class="$mq"
            v-scroll-reveal.stop="{
              duration: 3000,
              delay: 250,
              origin: 'top',
              distance: '40px',
            }"
          >
            <img :src="require(`@/assets/images/` + $config.tt)" alt />
          </div>
          <div class="desktop-top__trailer" :class="$mq">
            <div v-if="$config.youtube.display">
              <div
                @click="ytVideo"
                class="open-trailer"
                style="position: relative"
              >
                <video
                  src="@/assets/videos/sizzle.mp4"
                  loop
                  muted
                  autoplay
                ></video>
                <div class="trailer-misc">
                  <img
                    :style="[
                      ratio < 1.73
                        ? {
                            width: '40px',
                          }
                        : {
                            width: '95px',
                          },
                    ]"
                    class="play-btn"
                    src="@/assets/images/trailer/play.png"
                    alt
                  />
                  <div class="trailer-text">Trailer ansehen</div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="von"
            :class="$mq"
            v-scroll-reveal.stop="{
              delay: 1650,
            }"
          >
            <img src="@/assets/images/top/von.png" alt="" />
          </div>
          <div
            v-scroll-reveal.stop="{
              delay: 1650,
            }"
            class="buttons-wrapper"
            :class="$mq"
          >
            <div class="amazon" :class="$mq">
              <a
                href="https://www.amazon.de/Alles-bester-Ordnung-Joachim-Krol/dp/B0B58M82DC/ref=sr_1_2?keywords=alles+in+bester+ordnung&qid=1663060487&sprefix=alles+in+best%2Caps%2C114&sr=8-2"
                target="_blank"
                ><img src="@/assets/images/top/amazon.png" alt=""
              /></a>
            </div>
            <div class="prime" :class="$mq">
              <a
                href="https://www.amazon.de/Alles-bester-Ordnung-Corinna-Harfouch/dp/B0B28398K4/ref=sr_1_1?keywords=alles+in+bester+ordnung&qid=1663060454&sprefix=alles+in+best%2Caps%2C114&sr=8-1"
                target="_blank"
                ><img src="@/assets/images/top/prime.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
      <BaseKinostart :kinostart="kinostart" class="desktop-kinostart" />
    </div>
  </mq-layout>
</template>

<script>
/** @format */

import { EventBus } from '@/plugins/event-bus.js'

export default {
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
    ratio: {
      type: Number,
      default: 1.78,
    },
    trailer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFixed() {
      return this.position === 'fixed'
    },
  },
  methods: {
    ytVideo() {
      EventBus.$emit('open-yt')
    },
    kinoliste() {
      EventBus.$emit('open-kinoliste')
    },
  },
}
</script>

<style lang="scss">
/** @format */

.isFixed {
  position: fixed;
}
.isAbsolute {
  position: absolute;
}
.index {
  z-index: 0;
  position: relative;
  overflow-y: hidden;
  width: 100%;
  .desktop-kinostart {
    background-color: white;
    position: absolute;
    bottom: -0px;
    width: 100%;
    margin: 0;
    &.lg {
      font-size: 30px;
    }
    &.xl,
    &.xxl,
    &.xxxl,
    &.xxxxl {
      $min_width: 1024px;
      $max_width: 2560px;
      $min_font: 37px;
      $max_font: 70px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
    &.sm,
    &.md {
      font-weight: 500;
      $min_width: 300px;
      $max_width: 1023px;
      $min_font: 22px;
      $max_font: 55px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
  }
  .bg-top {
    position: relative;
    margin: 45px 55px 0 55px;

    img {
      pointer-events: none;
      position: relative;
      user-select: none;
      width: 100%;
      z-index: 0;
      display: block;
      max-width: 100%;
    }
  }
  .top-chars {
    position: absolute;
    left: 70px;
    top: 50px;
    width: 50%;
    img {
      width: 100%;
    }
    &.xxxl,
    &.xxxxl {
      width: 50%;
    }
  }
  .right-side {
    position: absolute;
    right: 50px;
    top: 140px;
    width: 45%;
    &.lg {
      top: 100px;
    }
    &.xl {
      top: 70px;
    }
    .right-side-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .packshot-buttons {
        position: absolute;
        bottom: 15px;
        left: -150px;
        &.lg {
          left: -60px;
        }
        &.xl {
          bottom: -70px;
          left: -100px;
        }
        &.xxxxl {
          bottom: -100px;
          left: -230px;
        }
        .packshot {
          width: 60%;
          margin-left: 10px;
          margin-bottom: 20px;
          margin: 0 auto;
          img {
            width: 100%;
          }
          &.lg {
            width: 40%;
            margin-left: 25px;
            margin-bottom: 10px;
          }
          &.xl {
            width: 50%;
            margin-left: 30px;
            margin-bottom: 60px;
          }
          &.xxl {
            left: -100px;
          }
          &.xxxxl {
            width: 90%;
          }
        }
      }
      .über {
        margin-bottom: 20px;
        margin-top: -60px;
        width: 65%;
        img {
          width: 100%;
        }
        &.lg {
          width: 55%;
          margin-top: -30px;
        }
        &.xl {
          margin-top: 0px;
        }
        &.xxxxl {
          width: 65%;
        }
      }
      .desktop-top__tt {
        width: 65%;
        &.lg {
          width: 60%;
        }
        &.xl {
          width: 50%;
        }
        &.xxxl,
        &.xxxxl {
          width: 70%;
        }
      }
      .desktop-top__trailer {
        pointer-events: auto;
        width: 60%;
        margin-bottom: 1.5rem;
        border: 8px solid white;
        &.lg {
          width: 50%;
          margin-bottom: 0.5rem;
        }
        &.xxxl,
        &.xxxxl {
          width: 65%;
        }
        cursor: pointer;
        .trailer-misc {
          position: absolute;
          top: 15px;
          left: 0;
          z-index: 111;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .trailer-text {
            font-weight: bold;
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            font-family: 'Open Sans';
          }
          .trailer-quelle {
            position: absolute;
            right: 5px;
            bottom: 5px;
            font-size: 12px;
            color: white;
            line-height: 1;
          }
        }
      }
      .von {
        width: 43%;
        img {
          width: 100%;
        }
        &.lg {
          width: 35%;
        }
        &.xxxl,
        &.xxxxl {
          width: 45%;
        }
      }
      .buttons-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
        &.lg {
          margin-top: 10px;
        }
        .prime {
          width: 50%;
          &.lg {
            width: 30%;
          }
          &.xl {
            width: 35%;
          }
          &.xxxxl {
            width: 60%;
          }
          img {
            width: 100%;
          }
        }
        .amazon {
          width: 50%;
          margin-right: 20px;
          &.lg {
            width: 30%;
            margin-right: 10px;
          }
          &.xl {
            width: 35%;
          }
          &.xxxxl {
            width: 60%;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
